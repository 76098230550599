// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

.card-img-top {
    max-height: 192px;
}

.youtube {
    position: relative;
    display: inline-block;
}
.youtube:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: transparent url('/assets/img/play.png') center center no-repeat;
}

.navbar {
    background-color: #e7f6f6 !important;
    border-bottom: 3px solid black;
}

.navbar .h5 {
    letter-spacing: -1px;    
}

@media (min-width: 1000px) {
    .home-banner {
        background: url('/assets/img/blue-back.jpg') top left / 50% 100% no-repeat, url('/assets/img/triangle2.jpg') top right / 120px 100% no-repeat;
        background-color: white;
    }

    .triangle1 {
        background: url('/assets/img/triangle1.svg'); background-size: cover;        
    }
}

@media (max-width: 1000px) {
    .home-banner {
        background: url('/assets/img/blue-back.jpg');
        background-size: 100% 45%;
        background-repeat: no-repeat;
        background-color: white;
    }
    .triangle1 {
        background: rgba(0,0,0,0);
    }
}

.home-banner-title {
    color: $cyan;
    padding-top: 68px;
    font-size: 2.1rem !important;
    margin-top:0px;
}

.home-banner-hero {
    font-size: 1rem;
    font-weight: 300;
    padding-right: 70px;
}

.home-banner-btn {
    font-size: 1.1rem;
    padding: 5px 15px 5px 15px;
    font-weight: 200;
    margin-top: 10px;
}

.embed-responsive {
    background: white;
    border: 5px solid white;
    border-radius: 20px;
}

.home-banner-video-container {
    border: 5px solid $blue;
    border-radius: 20px;
}

.video__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
.video__placeholder {
width: 100%;
position: absolute;
top: 8px;
left: 8px;
right: 8px;
bottom: 8px;
border-radius: 20px;
}

.video__youtube {
overflow: hidden;
object-fit: cover;
}

.video__button {
background: none;
border: 0;
cursor: pointer;
height: 100%;
left: 0;
position: absolute;
text-indent: -9999px;
top: 0;
transition: transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
width: 100%;
}

.video__button:before {
width:100%;
height:100%;
content: '';
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background: url('/assets/img/yt-dark.svg') no-repeat center center;
background-size: 10%; 
}

.video__button:hover:before {
background: url('/assets/img/yt-red.svg') no-repeat center center;
background-size: 10%; 
}

.bg-blue {
    background: url('/assets/img/blue-back.jpg') no-repeat;
    background-size: cover;
    padding-top: 1.5rem;
}

.bg-blue a {
    font-weight: 200 !important;
}

@media (min-width: 576px) {
    .text-padright {
        position: absolute;
        right: 0;    
    }
}

@media (max-width: 576px) {
    .text-padright {
        position: relative;
        display:block;
    }
}

.list-social-links {
    position: absolute !important;
    right: 0;
}

.card img {
    border: 3px solid $blue;
    border-radius: .375rem
}

.header-thin {
    height:345px;
}

.product-word-mosaic {
    color: $color-mosaic;
    animation: mosaic-animation;
}

.product-word-sbp {
    color: $color-sbp;
    animation: sbp-animation;
}

.product-word-mag {
    color: $color-mag;
    animation: mag-animation;
}

.product-word-mosaic, 
.product-word-sbp,
.product-word-mag
{
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    position: relative;
    animation-duration: 10s;
    animation-timing-function: steps(25, end);
    animation-iteration-count: infinite;
}

.product-word-mosaic::after, 
.product-word-sbp::after,
.product-word-mag::after
{
    content: "|";
    position: absolute;
    right: 0;
    animation: caret infinite;
    animation-duration: 1s;
    animation-timing-function: steps(1, end);
}

@keyframes caret {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

@keyframes mosaic-animation {
    0%, 33.3%, 100% {
      width: 0;
    }
    12.5%, 25% {
      width: 4.5em;
    }
}

@keyframes sbp-animation {
    0%, 33.3%, 66.6%, 100% {
      width: 0;
    }    
    40%, 60% {
      width: 2.2em;
    }
}

@keyframes mag-animation {
    0%, 66.6%, 100% {
      width: 0;
    }    
    72%, 94% {
      width: 2.6em;
    }
}